const navbarTextData = {
  en: {
    'navbar.experience': 'Experience',
    'navbar.ourpromise': 'Our promise',
    'navbar.businessmodel': 'Our Business Model',
    'navbar.ally': 'Allies',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contact',
    'navbar.language.spanish': 'Español (ES)',
    'navbar.language.english': 'English (EN)',
    'navbar.language.title': 'Language',
    'footer.rights': 'Merge 2022, All rights reserved.',
    'footer.privacy': 'Privacy policies',
    'newsletter.title': 'Subscribe to our content!',
    'newsletter.p': 'Get access to relevant digital marketing information by subscribing to our newsletter.',
    'newsletter.placeholder': 'Enter your email address',
    'newsletter.calltoaction': 'Subscribe'
  },
  es: {
    'navbar.experience': 'Experiencia',
    'navbar.ourpromise': 'Nuestra promesa',
    'navbar.businessmodel': 'Modelo de negocio',
    'navbar.ally': 'Aliados',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contacto',
    'navbar.language.spanish': 'Español (ES)',
    'navbar.language.english': 'English (EN)',
    'navbar.language.title': 'Idioma',
    'footer.rights': 'Merge 2022, todos los derechos reservados.',
    'footer.privacy': 'Politicas de privacidad', 
    'newsletter.title': '¡Recibe nuestro contenido! ',
    'newsletter.p': 'Accede a información relevante de marketing digital suscribiéndote a nuestro boletín.',
    'newsletter.placeholder': 'Ingresa tu correo electrónico',
    'newsletter.calltoaction': 'Suscríbete'
  }
};

export default navbarTextData;
